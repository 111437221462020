@import '@americastestkitchen/mise/mise-styles/main.scss';

.background {
  padding: 0 $miseSpacingx4;
  &.roseTint {
    background-color: $miseColorPrimaryLightTint;
  }
  &.roseBase {
    background-color: $miseColorPrimaryLightBase;
  }
}
.wrapper {
  margin: 0 auto;
}

.messagingSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0 $miseSpacingx10;
}

.header {
  margin: 0 0 $miseSpacingx3;
}
.paragraph {
  padding-bottom: $miseSpacingx1;
}

.heroImage {
  margin: auto;
  display: block;
}

.imageWrapper {
  display: flex;
  align-items: flex-end;
}
.listWrapper {
  padding-bottom: $miseSpacingx4;

  .listItem {
    list-style-type: disc;
    padding-bottom: $miseSpacingx1;
    margin-left: $miseSpacingx5;
  }
}

@media screen and (min-width: $miseBreakpointMd) {
  .wrapper {
    max-width: 500px;
  }
}

@media screen and (min-width: $miseBreakpointLg) {
  .messagingSection {
    grid-area: body;
  }

  .imageWrapper {
    grid-area: image;
  }
  .header {
    span {
      display: block;
    }
  }
  .body {
    width: 440px;
    &.right {
      margin-left: 90px;
    }
    &.left {
      margin-right: 90px;
    }
  }

  .wrapper {
    display: grid;
    max-width: 1136px;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    height: 464px;
  }

  .wrapperBodyRight {
    grid-template-areas: 'image body';
  }
  .wrapperBodyLeft {
    grid-template-areas: 'body image';
  }
}
